import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_USER = 'updateUser';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';
export const SET_LOCALE = 'setLocale';

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  currentLocale: localStorage.getItem('locale')
    ? localStorage.getItem('locale')
    : 'en',
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  getLocale(state) {
    return state.currentLocale;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('/login', credentials)
        .then(({ data }) => {
          // in case if wrong credentials
          if (data.code === 401) {
            reject(data);
            context.commit(SET_ERROR, data);
            return;
          }
          if (data.status === 'Error') {
            reject(data);
            context.commit(SET_ERROR, data);
            return;
          }
          context.commit(SET_AUTH, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [SET_LOCALE](context, data) {
    context.commit(SET_LOCALE, data);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('users', { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      // ApiService.post('/verifyotp')
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data.errors);
      //   });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put('user', user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.api_token, user);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    localStorage.removeItem('lastSeen');
  },
  [SET_LOCALE](state, lang) {
    state.currentLocale = lang;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
