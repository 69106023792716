<template>
  <router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
// Main demo style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
// @import "assets/sass/style.vue.rtl";

@font-face {
  font-family: "Mark Pro";
  src: local("MarkPro"),
    url("assets/fonts/MarkPro.ttf") format("truetype");
}
@font-face {
  font-family: "Mark Pro Black";
  src: local("MarkPro-Black"),
    url("assets/fonts/MarkPro-Black.ttf") format("truetype");
}
// @font-face {
//   font-family: "Mark Pro Bold";
//   src: local("MarkPro-Bold"),
//     url("assets/fonts/MarkPro-Bold.ttf") format("truetype");
// }
@font-face {
  font-family: "Mark Pro Medium";
  src: local("MarkPro-Medium"),
    url("assets/fonts/MarkPro-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Kufi Arabic";
  src: local("Noto Kufi Arabic"),
    url("assets/fonts/Noto_Kufi_Arabic/NotoKufiArabic-VariableFont_wght.ttf") format("truetype");
}

</style>

<script>

export default {
  name: "MetronicVue",
  mounted() {
  },
  // added watch to change page title dynamically throughout the website , just need to pass meta object in routes.
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Moon Program - Dashboard";
    },
  },
};
</script>
