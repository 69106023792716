export const translatedObject = {
  en: {
    navigation: {
      first: 'Main page',
      second: 'About',
      third: 'Our Clients',
      fourth: 'FAQs',
      button: 'Join Us',
      aboutUs : 'About Us',
      howItWorks: 'How it Works',
      contactUs : 'Contact Us'
    },
    moonLanding: {
      headerSection: {
        h1: 'Moon Program',
        h2: 'Do you have a lot of influence on social media?',
        h3: 'Then you’ve come to the right place!',
        p: `Moon gives you a chance to earn a percentage of sales promoting the brands you love by assigning you a unique coupon. All you need to do is register to start!`,
        button: 'Join Us Now',
      },
      advSection: {
        h1: 'How?',
        h3:
          'Easy! We will offer you a coupon for each brand you like and all you have to do is advertise!',
        subHeading1: 'Easy communication',
        subHeading2: 'To have a minimum of 9K followers',
        subHeading3: 'Active social media account',
        subHeading4: 'Stick to promoting the coupon codes',
        subHeading5: 'Creativity in advertising',
      },
      guideSection: {
        h1: 'Excited? Nice! To start:',
        step1:
          'Both of us will agree on the brands you would like to work on and your percentage from the sales.',
        step2: 'We will assign you a coupon for the brands you choose.',
        step3: 'We will send you a schedule for the ads during the month.',
        step4: 'Keep your coupons in the highlight section on Instagram.',
        step5:
          'Remind your followers of the coupon codes often to increase your sales.',
        step6:
          'We will transfer the amount accrued during the first 15 days of the following month.',
      },
      clientSection: {
        h1: 'Our Clients',
      },
      videoSliderSection:{
        h1: 'What do they say about this course?', 
        subHeading: "What an amazing experience I've had so far!",
        paragraph:'Ahmed here talks about his experience in working with the program for few months.'
      },
      faqSection: {
        h1: 'FAQs',
        questionAnswer: [
          {
            ques: 'How can I know how many orders I made?',
            ans: 'We will report to you how many orders you have made.',
          },
          {
            ques: 'How will you contact me?',
            ans: 'We will contact you via whatsapp. ',
          },
          {
            ques: 'Can I have A coupon code with my initials or name?',
            ans:
              'You will provide you a coupon code with a name and a number that is specifically for you, the name of the coupon code is related to the campaign. ',
          },
          {
            ques: 'How will I know my sales percentage?',
            ans:
              'We will update you regularly on how many orders and sales you have made.',
          },
          {
            ques: 'How will I receive my money?',
            ans:
              'We will transfer the money to your bank account on the first 15 days of the month.',
          },
          {
            ques: 'How much will I get from coupon sales?',
            ans:
              'It’s different for each site and brand you choose to advertise for, we will give you all the information once we contact you.',
          },
        ],
      },
    },
    join: {
      contact: {
        h1: 'Introduce yourself',
        namePlaceHolder: 'Name',
        phonePlaceholder: 'Phone number with country code',
        h2: 'Choose the platforms you are active on',
        continueBtn: 'Continue',
        warning: 'Please choose one of the social media platforms to continue',
      },
      socialMedia: {
        link: 'Link',
        linkPlaceholder: 'Add here',
        follower: 'Followers',
        followerPlaceholder: 'Add here',
        insight: 'Screenshot of your insights',
        insightPlaceholder: 'Drag or drop image...',
        infoCircleText: 'Click here for instructions',
        submitBtn: 'Continue',
        infoHeading: 'Share your insights',
        infoSubHeading1: 'Go to Insights',
        infoSubHeading2: 'Take a screenshot & attach it',
        mobileHeading: 'Provide us with your account stats',
      },
      referal: {
        h1:
          'Add a friend’s number who you think would benefit from Moon program',
        name: 'Account link',
        phone: 'Mobile number',
        email: 'Their email address',
        addAnother: 'Add another referral',
        skipBtn: 'Skip',
        submitBtn: 'Submit',
      },
      thankyou: {
        h1: 'Thank you!',
        h2:
          'We received your details and we will contact you at the earliest. We are happy for your interest in joining Moon Program',
      },
    },
  },
  ar: {
    navigation: {
      first: 'الصفحة الرئيسية',
      second: 'عن مون',
      third: 'عملائنا',
      fourth: 'الأسئلة الشائعة',
      button: 'انضم لنا',
      aboutUs : 'معلومات عنا',
      howItWorks: 'كيف تعمل',
      contactUs : 'اتصل بنا'
    },
    moonLanding: {
      headerSection: {
        h1: 'برنامج مون',
        h2: 'تعد نفسك مؤثر؟',
        h3: 'جیت المكان الصح',
        p: ` مون برنامج یعطیك الفرصة تعلن للمواقع اللي
        تحبها باننا نخصص لك كوبون ولك نسبة من
        المبیعات و تقدر تسجل الحین وتبدأ بالإعلان`,
        button: 'انضم لنا',
      },
      advSection: {
        h1: 'بتسأل كیف الطریقة؟',
        h3: ` سهلة جدا! بنوفر لك كود للمواقع اللي
          حاب تعلن لها وما علیك الا انك تعلن`,
        subHeading1: `   مرونة في
        التواصل`,
        subHeading2: `أن لا يقل عدد المتابعين
        لحسابك عن 9000 متابع!`,
        subHeading3: `حساب فعال في
        التواصل الاجتماعي`,
        subHeading4: `  الإلتزام بنشر
        الكوبونات`,
        subHeading5: `  حس إبداعي
        بالتسويق`,
      },
      guideSection: {
        h1: `تحمست؟
        حلو عشان تبدأ`,
        step1: ' بنتفق عالمواقع اللي حاب تعلن لها و نسبتك من المبیعات',
        step2: 'بنعطیك الكوبونات للمواقع اللي اخترتها',
        step3: 'بنحدد لك اوقات محدده تعلن فیها خلال الشهر',
        step4: 'بنحول لك النسبة خلال 15 یوم من الشهر الجاي',
        step5: 'تذكر بالكوبون دایم عشان تزید مبیعات كوبونك',
        step6: 'تثبت الكوبون على حساباتك في مواقع التواصل الاجتماعي',
      },
      clientSection: {
        h1: 'عملائنا',
      },
      videoSliderSection:{
        h1: 'ماذا يقولون حول هذه الدورة؟',
        subHeading: 'يا لها من تجربة مدهشة لقد كان حتى الآن!',
        paragraph:'Ahmed here talks about his experience in working with the program for few months.'
      },
      faqSection: {
        h1: 'الأسئلة الشائعة',
        questionAnswer: [
          {
            ques: 'ممكن یحقق لي برنامج مون دخل اضافي؟',
            ans: `كيد كل ما كنت فعال أكثر بنشر الكوبونات الخاصة فيك تزيد أرباحك
          وتحقق دخل إضافي!`,
          },
          {
            ques: 'كیف بتتواصلون معاي؟',
            ans: 'بنتواصل معاك عن طريق رقمك بالواتس اب.',
          },
          {
            ques: 'اقدر یكون عند اكواد باسمي؟',
            ans: `اح نوفر أكواد مخصصة باسم ورقم لك فقط، واسم الكود يكون مرتبط بالحملة
            التسويقية.`,
          },
          {
            ques: 'كیف اعرف نسبتي من المبیعات؟',
            ans: `عطيك أول بأول عدد الطلبات اللي حققتها ونرسلك كم نسبتك وصلت من
          المبيعات نهاية الشهر.`,
          },
          {
            ques: 'كیف راح استلم فلوسي؟',
            ans: `يتم تحويل نسبتك على حسابك البنكي خلال أول 15 يوم من الشهر.`,
          },
          {
            ques: 'كم نسبتي من مبیعات الكوبون؟',
            ans: `ختلف النسبة حسب الموقع اللي تختار تعلن له، لما نتواصل بالواتس اب
          بنعطيك هالمعلومات.`,
          },
        ],
      },
    },
    join: {
      contact: {
        h1: 'عرفنا بنفسك',
        namePlaceHolder: 'اسمك الكريم',
        phonePlaceholder: 'رقم جوالك ولا تنسى فتح الخط',
        h2: 'اختار المنصات اللي انت مبدع عليها',
        continueBtn: 'واصل',
        warning: 'يرجى اختيار إحدى منصات التواصل الإجتماعي للإستمرار',
      },
      socialMedia: {
        link: 'رابط حسابك',
        linkPlaceholder: 'اضف هنا',
        follower: 'كم متابعینك',
        followerPlaceholder: 'اضف هنا',
        insight: 'صورة الشاشة لإحصائيات حسابك',
        insightPlaceholder: 'اسحب وأفلت الصورة هنا',
        infoCircleText: 'انقر هنا للحصول على التعليمات',
        submitBtn: 'واصل',
        infoHeading: 'زودنا باحصائيات حسابك',
        infoSubHeading1: 'انتقل إلى علامة التبويب',
        infoSubHeading2: 'صور الشاشة وإرفاقها هنا',
        mobileHeading: 'زودنا باحصائيات حسابك',
      },
      referal: {
        h1: 'حاب تتوسط لأحد يدخل البرنامج ضيف معلومات حسابه وبنضبطه',
        name: 'رابط الحساب',
        phone: 'رقم الجوال',
        email: 'عنوان بريدهم الإلكتروني',
        addAnother: 'أضف شخص آخر',
        skipBtn: 'لا يوجد',
        submitBtn: 'واصل',
      },
      thankyou: {
        h1: 'تم',
        h2: `وصلتنا معلوماتك وبنتواصل معاك في أقرب فرصة سعيدين باهتمامك
        للانضمام لبرنامج مون`,
      },
    },
  },
};
