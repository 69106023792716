const ID_TOKEN_KEY = "id_token";
const APP_USER = "user";
const APP_USER_INFO = "userinfo";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getUser = () => {
  return window.localStorage.getItem(APP_USER);
};

export const saveToken = (token, user) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(APP_USER, `${user.first_name} ${user.last_name}`);
  window.localStorage.setItem(APP_USER_INFO, JSON.stringify(user));
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
